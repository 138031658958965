import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import WebsiteImage from "../images/internet-web-browser.svg"
import MobileImage from "../images/mobile-application.svg"
import SoftwareImage from "../images/farm-tractor.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Lubbock Developers for Lubbock Business" />
    <Hero />
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h2 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            What we do
          </h2>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-madrone-red inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 justify-center">
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 md:mr-6 flex flex-col text-center items-center md:shadow-lg">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-madrone-red mb-5 flex-shrink-0">
              <WebsiteImage className="w-10 h-10 fill-current" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Websites
              </h2>
              <p className="leading-relaxed text-base">
                We build websites to fit the needs of{" "}
                <strong>your business</strong>. We have experience building
                performant, cost effective websites to automate your workflows.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 md:mr-6 flex flex-col text-center items-center md:shadow-lg">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-madrone-red mb-5 flex-shrink-0">
              <MobileImage className="w-10 h-10 fill-current" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Mobile Apps
              </h2>
              <p className="leading-relaxed text-base">
                We have the know how to create mobile apps for iPhone and
                Android. We can build apps to streamline employee workflows and
                interact with customers.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 md:mr-6 md:mt-6 flex flex-col text-center items-center md:shadow-lg">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-madrone-red mb-5 flex-shrink-0">
              <SoftwareImage className="w-10 h-10 fill-current" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Custom Software
              </h2>
              <p className="leading-relaxed text-base">
                Have needs that a website or mobile app can't fulfill? We have
                experience with conference software, ag tech, and more. We can
                help you build the software that suits{" "}
                <strong>your business's</strong> needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
