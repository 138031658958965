import React from "react"
import HeroImage from "../images/undraw_feeling_proud_qne1.svg"

const Hero = () => (
  <section className="text-gray-700 body-font">
    <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
        <HeroImage className="object-cover object-center w-full h-full" />
      </div>
      <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
          Lubbock developers for
          <br className="hidden lg:inline-block" /> your Lubbock business
        </h1>
        <p className="mb-8 leading-relaxed">
          The first step is always the hardest, but you don't have to start the
          journey alone. Whether it's a website or an app, we have the skills to
          bring your idea to life.
        </p>
      </div>
    </div>
  </section>
)

export default Hero
